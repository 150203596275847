import { FC, useEffect, useState } from "react";
import PageOptions from "../Helpers/pageOptions/pageOptions";
import DrawMultiCarts from "../Helpers/pageViews/DrawMultiCarts/DrawMultiCarts";
import { Cart, Language, StoreEn } from "../../../types/types";
import { Card, Flex } from "../../../components/UI";
import { CartComparisonLegend } from "../Helpers/Ui";
import { usePageComparisonSettings } from "../../../Zustand";
import CartCompareSingleItemView from "../Helpers/pageViews/SingleItemView/CartCompareSingelItemView";
import LoginToStoreModal from "../../../components/CheckOut/LoginToStoreModal/LoginToStoreModal";
import { globalInitSearchCategory } from "../../../Zustand/stores/usePageComparisonSettings";

interface ICartComparisonPageMainContentProps {
  favoriteStore: StoreEn;
  otherStores: StoreEn[];
  cart: Cart;
  itemsKeys: string[];
  language: Language;
}

/** This is the main logic component of the ui*/
const CartComparisonPageMainContent: FC<ICartComparisonPageMainContentProps> = ({
  favoriteStore,
  otherStores,
  cart,
  itemsKeys,
  language,
}) => {
  const [isLoginToStoreModalOpen, setIsLoginToStoreModalOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState<StoreEn>(favoriteStore);
  const { setStoresToCompare, toggleCheckedStore, storesToCompare, searchedItemName, searchedCategory, selectedView } =
    usePageComparisonSettings();

  const handleSelectStore = (store: StoreEn) => {
    setSelectedStore(store);
    setIsLoginToStoreModalOpen(true);
  };

  useEffect(() => {
    setStoresToCompare(otherStores);
  }, [otherStores, setStoresToCompare]);

  const handleCloseStore = (store: StoreEn) => {
    if (store !== favoriteStore && storesToCompare.length > 1) {
      toggleCheckedStore(store);
    }
  };

  const itemsToDraw = itemsKeys.filter((key) => {
    const favoriteStoreItem = cart[key].product[favoriteStore];

    return (
      favoriteStoreItem.item_name.includes(searchedItemName) &&
      (searchedCategory === globalInitSearchCategory || favoriteStoreItem.category === searchedCategory)
    );
  });

  return (
    <Flex flexDirection="column" gap={20} style={{ minHeight: "75vh" }}>
      <PageOptions />
      {selectedView === "mobile" && (
        <CartCompareSingleItemView storesToCompare={storesToCompare} handleSelectStore={handleSelectStore} />
      )}
      {selectedView === "desktop" && (
        <DrawMultiCarts
          handleSelectStore={handleSelectStore}
          onCloseStore={handleCloseStore}
          itemsKeys={itemsToDraw}
          storesToCompare={storesToCompare}
        />
      )}
      <Card dataTour="legend-icons" style={{ width: "100%", margin: "auto" }}>
        <CartComparisonLegend />
      </Card>
      {isLoginToStoreModalOpen && (
        <LoginToStoreModal
          store={selectedStore}
          language={language}
          isOpen={isLoginToStoreModalOpen}
          setIsOpen={setIsLoginToStoreModalOpen}
          cart={cart}
        />
      )}
    </Flex>
  );
};

export default CartComparisonPageMainContent;
