import ProductCard from "../../../components/ProductCard/ProductCard";
import { Typography } from "../../../components/UI";
import { ProductDataType, StoreEn } from "../../../types/types";

const drawProducts = (store: StoreEn, productsSource: ProductDataType[]) => {
  if (!productsSource.length) return dataNotFound();

  return productsSource.map((product: ProductDataType, idx: number) => (
    <ProductCard productData={product} key={product[store].item_code + `${idx}`} item_code={product[store].item_code} />
  ));
};

export default drawProducts;

const dataNotFound = () => {
  return (
    <div className="products-main_data_not_found">
      <Typography.Title> Data Not Found</Typography.Title>
      <Typography.Paragraph>
        On this category probably products are not similar enough or not in stock{" "}
      </Typography.Paragraph>
    </div>
  );
};
