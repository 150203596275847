import { FC } from "react";
import { ImageComponent } from "../../UI";

interface IProductCardBodyProps {
  itemName: string;
  imgUrl: string;
  onClick: () => void;
  imgSize?: [string, string];
}

export const ProductCardBodyV2: FC<IProductCardBodyProps> = ({
  itemName,
  imgUrl,
  onClick,
  imgSize = ["70%", "100px"],
}) => {
  return (
    <div className="product_data_wrapper">
      <div
        className="item_img_card"
        tabIndex={0}
        role="contentinfo"
        onKeyDown={(e) => {
          if (e.key === "Enter") onClick();
        }}
      >
        <ImageComponent
          height={imgSize[1]}
          width={imgSize[0]}
          src={imgUrl}
          alt={itemName}
          onClick={onClick}
          // loading="lazy"
        />
      </div>
      <div className="item_name_card">{itemName} </div>
    </div>
  );
};

interface IProductCardFooterProps {
  itemPrice: number;
  unitQty: string;
}

export const ProductCardFooterV2: FC<IProductCardFooterProps> = ({ itemPrice, unitQty }) => {
  return (
    <div className="productPrice">
      <span>&#8362;</span>
      <span>{itemPrice}</span>
      <span>&nbsp;</span>
      <span>ל-</span>
      <span>&nbsp;</span>
      <span>{unitQty}</span>
    </div>
  );
};
