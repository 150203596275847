import { createPortal } from "react-dom";
import { ReactNode, FC, CSSProperties, useEffect } from "react";
import "./Modal.css";
import Button from "../Button/Button";
import SpinnerA from "../Loaders/spinnerA/SpinnerA";

import LogoIcon from "../Icons/Logo";
import { Language } from "../../../types/types";
// import { useToken } from "../ThemeProvider/ThemeProvider";
import { resolveDirClass, LangResolvePosition } from "../../../utils/utils";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import CloseBtn from "../CloseBtn/CloseBtn";

export type variantType =
  | "primary"
  | "secondary"
  | "contrast"
  | "success"
  | "info"
  | "light"
  | "dark"
  | "bgs"
  | "danger";

export interface ModalButtons {
  text: string | ReactNode;
  onClick: () => void | any;
  variant?: variantType;
  size?: "small" | "medium" | "large";
  color?: string;
  backgroundColor?: string;
  cssClass?: string;
}

interface ModalProps {
  visible?: boolean;
  title?: string | ReactNode;
  onCancel?: (e: React.MouseEvent<any, MouseEvent>) => void;
  onOk?: (e: React.MouseEvent<any, MouseEvent>) => void;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  children: ReactNode;
  width?: string | number;
  height?: string | number;
  buttons?: ModalButtons[];
  style?: CSSProperties;
  dir?: "ltr" | "rtl";
  withFooter?: boolean;
  position?: "center" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
  margin?: boolean;
  language?: Language;
  includeCloseBtn?: boolean;
  maskClosable?: boolean;
  zIndex?: number;
  autoTimer?: number;
  cssClass?: string;
  type?: "normal" | "warning" | "logo";
}

const Modal: FC<ModalProps> = ({
  title,
  visible,
  onCancel,
  onOk,
  okText,
  cancelText,
  loading,
  children,
  width,
  height,
  buttons,
  style,
  dir,
  withFooter = true,
  position = "center",
  margin,
  language,
  includeCloseBtn = true,
  maskClosable = true,
  zIndex,
  autoTimer,
  cssClass,
  type = "normal",
}) => {
  const { theme } = useCustomContext();

  // autoClose
  useEffect(() => {
    let timer: any;
    if (onCancel && autoTimer && visible) {
      timer = setTimeout(() => {
        // @ts-ignore
        onCancel();
      }, autoTimer * 1000);
    }
    return () => {
      clearTimeout(timer);
    };
  }, [visible, onCancel, autoTimer]);

  if (!visible) return null;

  let modalClassDynamicStyle: CSSProperties = {
    width: width ? width : "auto",
    height: height ? height : "fit-content",
    direction: dir ? dir : language && resolveDirClass(language),
    backgroundColor: theme.contrast === "theme-dark" ? "var(--bg-primary-color)" : "var(--white)",
  };

  if (style) {
    modalClassDynamicStyle = { ...modalClassDynamicStyle, ...style };
  }

  const drawButtons = () => {
    return buttons?.map((btn, idx) => {
      return (
        <Button
          cssClass={btn.cssClass}
          onClick={btn.onClick}
          variant={btn.variant}
          size={btn.size || "medium"}
          backgroundColor={btn.backgroundColor}
          key={idx}
        >
          {btn.text}
        </Button>
      );
    });
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    maskClosable && onCancel && onCancel(e);
  };

  const drawModalHeader = () => {
    if (!title) return null;
    let icon: ReactNode | null = null;
    if (type === "logo") icon = <LogoIcon size="24px" />;
    //
    else if (type === "warning") icon = <>⚠️</>;
    const typeClassName = `modal-header-${type}`;

    if (typeof title === "string") {
      return (
        <div className={`modal-header ${typeClassName}`}>
          {icon && <div>{icon}</div>}
          <div>{title}</div>
        </div>
      );
    }
    return <div className={typeClassName}>{title}</div>;
  };

  return createPortal(
    <div
      style={{ zIndex }}
      className={`modal-overlay ${resolveDirClass(language || "hebrew")} ${theme.showAnimation} ${
        theme.showLinksUnderline
      } ${theme.wordSpacing} ${theme.contrast} ${theme.fontSize}`}
      onClick={handleOverlayClick}
    >
      <div
        className={`modal ${margin ? "margin-modal" : ""} ${position.split("-").join(" ")} ${cssClass ?? ""}`}
        style={modalClassDynamicStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          {drawModalHeader()}
          {onCancel && includeCloseBtn && (
            <CloseBtn
              position={{ top: 5, [LangResolvePosition(language)]: 5 }}
              onClick={onCancel}
              type="circle-hover"
              tabIndex={0}
              data-tour="modal-close-btn"
            />
          )}
        </div>
        <div className="modal-content">{children}</div>
        {withFooter && (
          <div className="modal-footer">
            {buttons?.length ? (
              drawButtons()
            ) : (
              <>
                <Button
                  dataTour="modal-confirm"
                  ariaLabel="ok"
                  onClick={onOk}
                  disabled={loading}
                  variant={type === "warning" ? "danger" : "primary"}
                >
                  {loading ? <SpinnerA size={20} /> : okText || "אישור"}
                </Button>
                <Button dataTour="modal-cancel" ariaLabel="cancel" type="default" onClick={onCancel}>
                  {cancelText || "ביטול"}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
