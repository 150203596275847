import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Checkbox, Collapse, CollapseItemType, SideBar, Tooltip } from "../UI";
import { FilterOptionsState } from "./helpers/types";
import { FilterIcon, SettingsIcon } from "../UI/Icons";
import "./ProductsFilter.css";
import useDebounce from "../../hooks/useDebounce";
import MultiRangeSlider from "../UI/RangeSlider/multiRangeSlider";

interface IProps {
  filterOptions: FilterOptionsState;
  setFilterOptions: Dispatch<SetStateAction<FilterOptionsState>>;
}

const ProductsFilter = ({ filterOptions, setFilterOptions }: IProps) => {
  const [filters, setFilters] = useState(filterOptions);
  const renderCount = useRef(0);

  useEffect(() => {
    if (renderCount.current === 1) {
      setFilters(filterOptions);
    }
    renderCount.current++;
  }, [filterOptions]);

  const debouncedHandleInput = useDebounce((event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterOptions((p) => ({ ...p, searchInput: event.target.value }));
  });

  const debounceUpdatePrices = useDebounce((values: { min: number; max: number }) => {
    setFilterOptions((p) => ({ ...p, maxPrice: values.max, minPrice: values.min }));
  }, 500);

  const debounceHandlePriceRangeChange = useCallback((values: { min: number; max: number }) => {
    debounceUpdatePrices(values);
    // eslint-disable-next-line
  }, []);

  const handleAllPromotions = (isChecked?: boolean) => {
    setFilterOptions((p) => ({
      ...p,
      promotionsOnlyRegular: isChecked ?? false,
      promotionsOnlyClub: isChecked ? false : p.promotionsOnlyClub,
    }));
  };
  const handleClubPromotions = (isChecked?: boolean) => {
    setFilterOptions((p) => ({
      ...p,
      promotionsOnlyClub: isChecked ?? false,
      promotionsOnlyRegular: isChecked ? false : p.promotionsOnlyRegular,
    }));
  };

  const handleSegmentCheck = (segment: string, isChecked?: boolean) => {
    setFilterOptions((prev) => {
      if (isChecked) {
        return {
          ...prev,
          segments: { ...prev.segments, [segment]: filters.segments[segment] },
        };
      } else {
        const { [segment]: _, ...rest } = prev.segments;
        return { ...prev, segments: rest };
      }
    });
  };

  const handleBrandsChanges = (brand: string, isChecked?: boolean) => {
    setFilterOptions((prev) => {
      if (isChecked) {
        return {
          ...prev,
          brands: { ...prev.brands, [brand]: filters.brands[brand] },
        };
      } else {
        const { [brand]: _, ...rest } = prev.brands;
        return { ...prev, brands: rest };
      }
    });
  };

  const items: CollapseItemType[] = [
    {
      title: (
        <input
          placeholder="חיפוש חופשי"
          type="text"
          className="filters-search-ui-input"
          name={"searchInput"}
          onChange={debouncedHandleInput}
          title=""
          autoComplete="off"
        />
      ),
      content: "",
    },
    {
      title: "מחירים",
      content: (
        <>
          <div style={{ width: "95%", margin: "15px auto" }}>
            <MultiRangeSlider
              key={renderCount.current === 1 ? "forceRender" : "keep"}
              min={filters.minPrice}
              max={filters.maxPrice}
              onChange={debounceHandlePriceRangeChange}
            />
          </div>
        </>
      ),
    },
    {
      title: "מבצעים",
      content: (
        <div>
          <Checkbox
            onChange={(e) => handleAllPromotions(e?.target.checked)}
            checked={filterOptions.promotionsOnlyRegular}
            label="מבצעים בלבד"
          />
          <Checkbox
            onChange={(e) => handleClubPromotions(e?.target.checked)}
            checked={filterOptions.promotionsOnlyClub}
            label="מבצעים לחברי מועדון בלבד:"
          />
        </div>
      ),
    },
    {
      title: "סוג",
      content: Object.entries(filters.segments).map(([name, count]) => {
        return (
          <Checkbox
            key={name}
            label={`${name === "null" ? "חסר סיווג" : name}(${count})`}
            checked={!!filterOptions.segments[name]}
            onChange={(e) => handleSegmentCheck(name, e?.target.checked)}
          />
        );
      }),
    },
    {
      title: "מותג",
      content: Object.entries(filters.brands).map(([name, count], i) => {
        return (
          <Checkbox
            onChange={(e) => handleBrandsChanges(name, e?.target.checked)}
            checked={!!filterOptions.brands[name]}
            label={name ? `${name}(${count})` : "?"}
            key={name + i}
          />
        );
      }),
    },
  ];

  const handleResetFilters = () => {
    setFilterOptions(() => ({ ...filters, isOpen: true }));
    renderCount.current = 1;
  };
  const handleClose = () => {
    setFilterOptions((prev) => ({ ...prev, isOpen: false }));
    renderCount.current = 2;
  };

  return (
    <SideBar
      FloatBtn={
        filterOptions.isOpen ? undefined : (
          <div
            onClick={() => {
              setFilterOptions((p) => ({ ...p, isOpen: true }));
            }}
            id="product-filter_float-btn"
          >
            <FilterIcon color="var(--primary-color)" clickable />
          </div>
        )
      }
      shadow={false}
      isOpen={filterOptions.isOpen}
    >
      <div id="filter-container">
        <div id="filter-btns-header">
          <span className="filter-reset-filters-btn" onClick={handleResetFilters}>
            <Tooltip title={"reset filters"} placement="right">
              <SettingsIcon size="1em" color="var(--primary-color)" clickable />
            </Tooltip>
          </span>
          <span>אפשרויות סינון</span>
          <span className="clickable-icon" onClick={handleClose}>
            ››
          </span>
        </div>
        <div id="filter-items-wrapper">
          <Collapse items={items} />
        </div>
      </div>
    </SideBar>
  );
};

export default ProductsFilter;
