import React from "react";
import { ComingSoon } from "../../UI";

const MyPreference = () => {
  return (
    <div>
      <ComingSoon description="Custom Categories: Enable users to define categories they care about (e.g., organic, gluten-free, kosher). Preferred Brands: Let users prioritize specific brands or product lines.">
        העדפות שלי
      </ComingSoon>
    </div>
  );
};

export default MyPreference;
