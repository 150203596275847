import { useCallback, useMemo, useState } from "react";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { StoreEn } from "../../../types/types";
import OnlineStoresDict from "../../../utils/onlineStoresDict";
import "./StorePreference.css";
import { Card, Flex, Modal } from "../../UI";
import { InfoIcon, WarningIcon } from "../../UI/Icons";
import { useCartStore } from "../../../Zustand";

type storeType = { id: string; name: StoreEn; label: string; logo: any };
type WarningModalActions = { itemsCount: number; selectedStore: StoreEn; action: "favorite" | "other" };

const StoresPreference = () => {
  const {
    onlineStores: { favoriteStore, hasClubCard, otherStores },
    setOnlineStores,
    user: { language },
  } = useCustomContext();
  const { clearCart, cart } = useCartStore();

  const [warningModal, setWarningModal] = useState<WarningModalActions | null>(null);

  const labels = useMemo(() => {
    return {
      smallExplain: {
        hebrew: "הוספת פריטים לעגלה מתבססת על נתוני החנות הראשית. בסיום, העגלה תשוכפל לחנויות האחרות.",
        english:
          "Items are added to the cart based on the favorite store's data. At the end, the cart will be duplicated for other stores.",
        arabic:
          "تتم إضافة العناصر إلى السلة بناءً على بيانات المتجر المفضل. في النهاية، سيتم تكرار السلة للمتاجر الأخرى.",
        russian:
          "Товары добавляются в корзину на основе данных из основного магазина. В конце корзина будет продублирована для других магазинов.",
      },
      warning: {
        hebrew: "כל שינוי ינקה את העגלה הנוכחית.",
        english: "Any changes will clear your current cart.",
        arabic: "أي تغيير سيمسح السلة الحالية.",
        russian: "Любые изменения очистят текущую корзину.",
      },
      gridTitle: {
        hebrew: "הוספת/הסרת חנוית",
        english: "Add/Remove Stores",
        arabic: "إضافة/إزالة المتاجر",
        russian: "Добавить/Удалить магазины",
      },
      otherTableTitle: {
        hebrew: "חנויות אליהן ארצה להשוות",
        english: "Stores I Want to Compare With",
        arabic: "المتاجر التي أرغب بالمقارنة معها",
        russian: "Магазины для сравнения",
      },
      storeNameColumn: {
        hebrew: "שם הרשת",
        english: "Store Name",
        arabic: "اسم المتجر",
        russian: "Название магазина",
      },
      HasClubCardColumn: {
        hebrew: "חבר מועדון *",
        english: "Club Member *",
        arabic: "عضوية النادي *",
        russian: "Член клуба *",
      },
      favoriteTableTitle: {
        hebrew: "החנות הראשית שלי ",
        english: "My Main Store",
        arabic: "متجري الرئيسي",
        russian: "Мой главный магазин",
      },
      favoriteBtn: {
        hebrew: "חנות ראשית *",
        english: "Main Store *",
        arabic: "المتجر الرئيسي *",
        russian: "Главный магазин *",
      },
      add: {
        hebrew: "הוסף את ",
        english: "Add ",
        arabic: "أضف ",
        russian: "Добавить ",
      },
      remove: {
        hebrew: "הסר את ",
        english: "Remove ",
        arabic: "إزالة ",
        russian: "Удалить ",
      },
    };
  }, []);

  const stores: storeType[] = useMemo(() => {
    return OnlineStoresDict.getStores(language).sort((a, b) =>
      a.name === favoriteStore || otherStores.includes(a.name)
        ? -1
        : b.name === favoriteStore || otherStores.includes(b.name)
        ? 1
        : 0
    );
    // eslint-disable-next-line
  }, [language]);

  const toggleFavoriteStore = useCallback(
    (store: StoreEn) => {
      if (favoriteStore !== store) {
        const others = otherStores.filter((s) => s !== store && s !== favoriteStore);
        setOnlineStores((prev) => ({
          favoriteStore: store,
          hasClubCard: prev.hasClubCard,
          otherStores: [...others, prev.favoriteStore],
        }));
      }
    },
    [favoriteStore, otherStores, setOnlineStores]
  );

  const toggleSelectedStore = useCallback(
    (store: StoreEn) => {
      if (store === favoriteStore) return;
      else if (otherStores.includes(store)) {
        setOnlineStores((prev) => ({
          ...prev,
          otherStores: prev.otherStores.filter((s) => s !== store),
          hasClubCard: prev.hasClubCard.filter((s) => s !== store),
        }));
      } else {
        setOnlineStores((prev) => ({ ...prev, otherStores: [...prev.otherStores, store] }));
      }
    },
    [favoriteStore, otherStores, setOnlineStores]
  );

  const toggleClubCardStore = (store: StoreEn) => {
    const isAlreadyIncluded = hasClubCard.includes(store) && [favoriteStore, ...otherStores].includes(store);
    if (isAlreadyIncluded) {
      setOnlineStores((prev) => ({ ...prev, hasClubCard: prev.hasClubCard.filter((s) => s !== store) }));
    } else {
      setOnlineStores((prev) => ({ ...prev, hasClubCard: [...prev.hasClubCard, store] }));
    }
  };

  const handleAction = (storeName: StoreEn, action: WarningModalActions["action"]) => {
    const cartItemsCount = Object.keys(cart).length;
    if (cartItemsCount === 0) {
      setWarningModal(null);
      if (action === "favorite") {
        toggleFavoriteStore(storeName);
      } else if (action === "other") {
        toggleSelectedStore(storeName);
      }

      return;
    }

    setWarningModal({ action: action, selectedStore: storeName, itemsCount: cartItemsCount });
  };

  const WarningModal = useCallback(() => {
    if (!warningModal) return null;

    const onCancel = () => {
      setWarningModal(null);
    };

    const onOk = () => {
      if (warningModal.action === "favorite") {
        toggleFavoriteStore(warningModal.selectedStore);
      } else if (warningModal.action === "other") {
        toggleSelectedStore(warningModal.selectedStore);
      } else {
        throw new Error("bad action");
      }
      onCancel();
      clearCart();
    };

    const text = {
      english: `<p>Updating store will <strong>clear your cart</strong>.</p>
    <p>You have <span style='color:red;'>{x} items</span> in your cart.</p>
    <p>Are you sure you want to proceed?</p>`,
      hebrew: `<p>פעולת עדכון חנות<strong> תרוקן את העגלה </strong></>
    <p>יש לך <span style='color:red;'>{x} פריטים</span> בעגלה.
    האם להמשיך בכל זאת?</p>`,
      arabic: `<p>تحديث المتجر المفضل لديك سيؤدي إلى <strong>مسح سلة التسوق الخاصة بك</strong>.</p>
<p>لديك <span style='color:red;'>{x} عناصر</span> في سلة التسوق الخاصة بك.</p>
<p>هل أنت متأكد أنك تريد المتابعة؟</p>`,
      russian: `<p>У вас <span style='color:red;'>{x} товаров</span> в корзине.</p><p>Обновление избранного магазина <strong>очистит вашу корзину</strong>. Вы уверены, что хотите продолжить?</p>`,
    };

    const warningDict = {
      english: "Warning",
      hebrew: "אזהרה",
      arabic: "تحذير",
      russian: "Предупреждение",
    };

    return (
      <Modal
        visible={true}
        onOk={onOk}
        onCancel={onCancel}
        type="warning"
        title={warningDict[language]}
        language={language}
      >
        <div
          style={{ textAlign: "center", padding: 15 }}
          dangerouslySetInnerHTML={{ __html: text[language].replace("{x}", warningModal.itemsCount.toString()) }}
        ></div>
      </Modal>
    );
  }, [clearCart, language, toggleFavoriteStore, toggleSelectedStore, warningModal]);

  const storeSelectionGrid = () => {
    return (
      <div className="store-selection_store-grid">
        {stores.map((store) => (
          <div
            onClick={(e) => {
              e.stopPropagation();
              handleAction(store.name, "other");
              // toggleSelectedStore(store.name);
            }}
            key={store.id}
            className={`store-selection_store-item  ${
              otherStores.some((s) => s === store.name) ? "store-preference_active" : ""
            } ${favoriteStore === store.name ? "active-favorite" : ""}`}
          >
            {[favoriteStore, ...otherStores].some((s) => s === store.name) && (
              <div className="store-selection_store-checkbox">
                {favoriteStore === store.name ? (
                  <div style={{ color: "#FFAA00", transform: "scale(1.5)" }}>★</div>
                ) : (
                  <div style={{ color: "var(--primary-color)" }}>✓</div>
                )}
              </div>
            )}
            <div className="store-selection_store-header">
              <span className="store-selection_store-logo">
                <img src={store.logo} alt={`${store.label} logo`} height={40} width={100} />
              </span>
              <h5 className="store-selection_store-label">
                <span>
                  {otherStores.some((s) => s === store.name)
                    ? labels.remove[language]
                    : store.name === favoriteStore
                    ? labels.favoriteTableTitle[language]
                    : labels.add[language]}
                </span>
                <span>{store.label}</span>
              </h5>
            </div>
            <div className="store-selection_store-actions">
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  // toggleFavoriteStore(store.name);
                  handleAction(store.name, "favorite");
                }}
                className={`store-selection_btn favorite-btn ${
                  favoriteStore === store.name ? "store-preference_active" : ""
                }`}
              >
                {favoriteStore === store.name
                  ? labels.favoriteBtn[language].replace("*", "✓")
                  : labels.favoriteBtn[language].replace("*", "")}
              </button>

              <button
                style={{
                  visibility:
                    otherStores.some((s) => s === store.name) || favoriteStore === store.name ? "visible" : "hidden",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  toggleClubCardStore(store.name);
                }}
                className={`store-selection_btn store-selection_club-card-btn ${
                  hasClubCard.some((s) => s === store.name) ? "store-preference_active" : ""
                }`}
              >
                {hasClubCard.some((s) => s === store.name)
                  ? labels.HasClubCardColumn[language].replace("*", "✓")
                  : labels.HasClubCardColumn[language].replace("*", "")}
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const drawTable = (storesToRender: StoreEn[]) => {
    return (
      <table className="store-selection_summary-table">
        <thead>
          <tr>
            <th>{labels.storeNameColumn[language]}</th>
            <th>{labels.HasClubCardColumn[language].replace("*", "")}</th>
          </tr>
        </thead>
        <tbody>
          {stores
            .filter((s) => storesToRender.includes(s.name))
            .map((store) => (
              <tr key={store.id}>
                <td>{store.label}</td>
                <td style={{ textAlign: "center" }}>
                  {hasClubCard.some((s) => s === store.name) ? (
                    <span className="store-selection_club-card-status green">✔</span>
                  ) : (
                    <span className="store-selection_club-card-status red">✘</span>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    );
  };

  const summaryTables = () => {
    return (
      <Flex flexDirection="column" gap={30}>
        <Flex flexDirection="column" gap={5}>
          <h5>{labels.favoriteTableTitle[language]}</h5>
          {drawTable([favoriteStore])}
        </Flex>
        <Flex flexDirection="column" gap={5}>
          <h5>{labels.otherTableTitle[language]}</h5>
          {drawTable(otherStores)}
        </Flex>
      </Flex>
    );
  };

  const explain = (
    <div style={{ alignSelf: "end" }}>
      <Flex gap={5}>
        <span>
          <InfoIcon size="1em" />
        </span>
        <span>{labels.smallExplain[language]}</span>
      </Flex>
      <Flex gap={5}>
        <span>
          <WarningIcon size="1em" />
        </span>
        <span>{labels.warning[language]}</span>
      </Flex>
    </div>
  );

  return (
    <Flex cssClass="store-selection" flexDirection="column" justify="center" gap={10}>
      <Flex gap={10} style={{ justifyContent: "space-around" }} responsive={{ sm: { flexDirection: "column" } }}>
        <Card style={{ width: 400, maxHeight: 600 }} responsive={{ sm: { width: 330, margin: "auto" } }}>
          <Flex flexDirection="column" justify="space-between" style={{ height: "100%" }}>
            {summaryTables()}
            {explain}
          </Flex>
        </Card>
        <Card
          style={{ width: 900, flex: 1, flexShrink: 0, maxHeight: 600 }}
          responsive={{ sm: { width: 330, margin: "auto" } }}
        >
          <h5 style={{ marginBottom: 3 }}>{labels.gridTitle[language]}</h5>
          {storeSelectionGrid()}
        </Card>
      </Flex>
      {WarningModal()}
    </Flex>
  );
};

export default StoresPreference;
