import React from "react";
import "./MyScore.css";
import { ComingSoon } from "../../UI";

const MyScore = () => {
  return (
    <div>
      <ComingSoon description="צבירת נקודות - בקרוב">הנקודות שלי</ComingSoon>
    </div>
  );
};

export default MyScore;
