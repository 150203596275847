import { useEffect } from "react";
import { useCartStore, usePageComparisonSettings } from "../../../../../Zustand";
import useCustomContext from "../../../../../hooks/useCustomContext/useCustomContext";
import { InputUI } from "../../../../../components/UI";
import CategoriesMap from "../../../../../assets/Data/categories";
import { globalInitSearchCategory } from "../../../../../Zustand/stores/usePageComparisonSettings";

const SearchInCartOption = () => {
  const { searchedCategory, setSearchedCategory, searchedItemName, setSearchedItemName } = usePageComparisonSettings();
  const { cart } = useCartStore();
  const {
    user: { language },
    onlineStores: { favoriteStore },
  } = useCustomContext();

  const favoriteCartCategories = Object.keys(cart).map((key) => cart[key].product[favoriteStore].category);

  const categories = CategoriesMap.filter((cat) => favoriteCartCategories.includes(cat.id)).map((category) => {
    return (
      <option value={category.id} key={category.id}>
        {category[language]}
      </option>
    );
  });

  categories.unshift(<option value={globalInitSearchCategory}>כל הקטגרויות</option>);

  useEffect(() => {
    return () => {
      setSearchedItemName("");
      setSearchedCategory(-1);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div style={{ flex: 10, maxWidth: 500 }}>
        <InputUI
          // label="חפשו פריטים בתוך העגלה"
          className="data-tour-search-in-cart"
          tabIndex={0}
          placeholder="חפשו פריטים בתוך העגלה"
          value={searchedItemName}
          onChange={(e: any) => setSearchedItemName(e.target.value)}
        />
      </div>

      {categories.length > 2 && (
        <div style={{ flex: 1, maxWidth: 500 }}>
          <select
            data-tour="filter-categories"
            className="form__input"
            defaultValue={"all"}
            onChange={(e) => setSearchedCategory(parseInt(e.target.value))}
            value={searchedCategory}
          >
            {categories}
          </select>
        </div>
      )}
    </>
  );
};

export default SearchInCartOption;
