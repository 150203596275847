import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import ExtraInformation from "./ExtraInformation/ExtraInformation";
import SelectedItemImages from "./SelectedItemImages/SelectedItemImages";
import { Language, ProductDataType, StoreEn } from "../../types/types";
import { dictStores } from "../../dictionary/dictStores";
import { dictButtonsAndActions } from "../../dictionary/dictButtons";
import { SelectSearch, Modal, ImageComponent, Button, Flex } from "../UI";
import "./SelectedItem.css";
import { directionByLanguage, resolveImgUrl } from "../../utils/utils";
import serverAPI from "../../api/server";
import Promotion from "../ProductCard/Promotions/Promotion";
import { PromoIcon } from "../UI/Icons";
import { ItemFeatureType } from "../Cart/CartItem/CartItem";
import ItemNotFoundDescription from "./ItemNotFoundDescription/ItemNotFoundDescription";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import OnlineStoresDict from "../../utils/onlineStoresDict";
import { useCartStore } from "../../Zustand";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedProductProp: ProductDataType;
  setSelectedProductProp?: Dispatch<SetStateAction<ProductDataType>>;
  stores: StoreEn[];
  language: Language;
  featureType?: ItemFeatureType;
  selectedStore: StoreEn;
};

const SelectedItem: FC<Props> = ({
  isOpen,
  setIsOpen,
  selectedProductProp,
  stores,
  language,
  featureType = "normal",
  selectedStore,
  setSelectedProductProp,
}) => {
  const [tempProductDataState, seTempProductDataState] = useState(selectedProductProp);
  const {
    onlineStores: { favoriteStore },
  } = useCustomContext();

  const { cart, addToCart, removeFromCart } = useCartStore();
  const [itemCode, setItemCode] = useState("");

  useEffect(() => {
    seTempProductDataState(selectedProductProp);
    setItemCode(selectedProductProp[favoriteStore as StoreEn].item_code);
  }, [selectedProductProp, favoriteStore, selectedStore]);

  const getDebounceData = useMemo(() => {
    if (featureType === "comparison_cart") {
      const body = {
        store: OnlineStoresDict.convertOnlineStoreToObj(selectedStore),
        searchValues: "",
        mCode: 1,
      };

      return {
        body: body,
        searchKey: "searchValues",
        getOptionsFromApi: async (): Promise<any> => {
          const res: { data: ProductDataType[] } = await serverAPI("products/common/v2/get", body);
          return res.data.map((i: any) => {
            return { value: i[selectedStore].item_code, label: i[selectedStore].item_name, data: i };
          });
        },
      };
    }
  }, [selectedStore, featureType]);

  const placeHolder = useMemo(() => {
    const favoriteProductName = tempProductDataState[favoriteStore].item_name;
    const productName = tempProductDataState[selectedStore]?.item_name ?? favoriteProductName;
    return {
      hebrew: ` ניתן לחפש כאן ישירות ב-${dictStores[selectedStore][language]} ולהחליף ${productName} במוצר דומה לבחירתכם`,
      english: `Search in ${dictStores[selectedStore][language]} and replace ${productName} with other similar product`,
      russian: `Ищите в ${dictStores[selectedStore][language]} и замените ${productName} другим продуктом`,
      arabic: `ابحث في ${dictStores[selectedStore][language]} واستبدل ${productName} بمنتج آخر`,
    };
  }, [selectedStore, tempProductDataState, favoriteStore, language]);

  if (!isOpen) return null;

  const handleSelectedFromSearch = (selectedItem: ProductDataType) => {
    if (isOpen && selectedItem) {
      const newSelectedItemData = selectedItem[selectedStore];
      newSelectedItemData.source = "FE-user-select";
      newSelectedItemData.img_url = resolveImgUrl(selectedStore, newSelectedItemData.img_url);
      seTempProductDataState((prev) => ({ ...prev, [selectedStore]: newSelectedItemData }));
    }
  };

  const handleCloseModal = () => {
    seTempProductDataState(() => selectedProductProp);
    setIsOpen(false);
  };

  const handleAddToCart = () => {
    addToCart(itemCode, tempProductDataState, 1);
    setSelectedProductProp && setSelectedProductProp(tempProductDataState);
    setIsOpen(false);
  };

  const handleRemoveFromCart = () => {
    removeFromCart(itemCode);
  };

  const drawSelectSearchLabel = (option: { value: string; label: string; data: ProductDataType }) => {
    const imgUrl = resolveImgUrl(selectedStore, option.data[selectedStore].img_url);

    const price = option.data[selectedStore].item_price;
    const name = option.label;

    return (
      <Flex align="center" style={{ flex: 1, borderBottom: "1px solid var(--border-color)" }}>
        <ImageComponent src={imgUrl} alt={option.value} width={40} height={40} />
        <span style={{ flex: 2 }}>&nbsp; {name}</span>
        <span className="scrollable" style={{ maxHeight: "100px", overflow: "auto" }}>
          <Promotion
            productData={option.data}
            store={selectedStore}
            type="search"
            title={<PromoIcon color="var(--primary-color)" />}
          />
        </span>
        {
          <span className="main-search-price-range2">
            &nbsp; {price} <span>&#8362;</span>
          </span>
        }
      </Flex>
    );
  };

  // We render select search only if it version 1 or using comparison cart feature . (both cases we not do so for favorite store)
  const drawSelectSearch = () => {
    if (selectedStore !== favoriteStore && featureType === "comparison_cart")
      return (
        <div data-tour="not-found-item-search-modal" className="selected_item-select-search-wrapper">
          <Flex
            justify="center"
            gap={15}
            align="center"
            style={{ background: "var(--white)", paddingBottom: 15 }}
            responsive={{ sm: { flexDirection: "column", gap: 2, alignItems: "normal", width: "90%" } }}
          >
            {/* <label htmlFor="select-search-selected-item">חיפוש פריט אחר</label> */}
            <SelectSearch
              name="select-search-selected-item"
              getDebounceData={getDebounceData}
              resetProps={[selectedStore]}
              placeholder={placeHolder[language]}
              handleSelected={handleSelectedFromSearch}
              Label={drawSelectSearchLabel}
            />
          </Flex>
        </div>
      );
    else return null;
  };

  return (
    <Modal
      language={language}
      visible={isOpen}
      onCancel={handleCloseModal}
      onOk={itemCode in cart ? handleRemoveFromCart : handleAddToCart}
      okText={dictButtonsAndActions[itemCode in cart ? "removeFromCart" : "addToCart"][language]}
      cancelText={dictButtonsAndActions.cancel[language]}
      loading={false}
      title={
        tempProductDataState[selectedStore]?.item_name ?? `פריט חסר - ${tempProductDataState[favoriteStore]?.item_name}`
      }
      dir={directionByLanguage(language)}
    >
      <div className="selected_item_wrapper">
        {drawSelectSearch()}
        <div className="selected_item_meta_wrapper">
          {tempProductDataState[selectedStore] && (
            <ExtraInformation productData={tempProductDataState} store={selectedStore} language={language} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flex: 1,
              width: "100%",
            }}
          >
            {tempProductDataState[selectedStore]?.item_code !== selectedProductProp[selectedStore]?.item_code && (
              <Button variant="contrast" width={200} onClick={handleAddToCart}>
                אישור החלפת מוצר
              </Button>
            )}
            {!tempProductDataState[selectedStore] && <ItemNotFoundDescription language={language} />}
            {tempProductDataState[selectedStore] && (
              <SelectedItemImages front={tempProductDataState} stores={stores} currentStore={selectedStore} />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectedItem;
