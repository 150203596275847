import { FC, ReactNode, useMemo, useState } from "react";
import "./Promotion.css";
import { ProductDataType, StoreEn } from "../../../types/types";
import { PromoIcon } from "../../UI/Icons";
import { addSpacingBetweenNumbersAndLetters } from "../../../utils/utils";
import { Flex } from "../../UI";

type Props = {
  productData: ProductDataType;
  store: StoreEn;
  type?: "main" | "search";
  alwaysShowData?: boolean;
  promoIconColor?: string;
  title?: string | ReactNode;
};

const Promotion: FC<Props> = ({
  store,
  productData,
  type = "main",
  title = "מבצעים",
  promoIconColor,

  alwaysShowData = false,
}) => {
  const [isHovered, setIsHovered] = useState(alwaysShowData);

  const promoData = useMemo(() => {
    return productData[store].promotions;
  }, [productData, store]);

  const generateDescription = useMemo(() => {
    if (!promoData?.length) return null;

    return promoData.map((p, i) => {
      const drawLine = promoData.length > 1 && i !== promoData.length - 1 ? "bottom_line" : "";
      return (
        <div className={`promotion-context ${drawLine}`} key={p.item_code + p.promotion_id + i}>
          <Flex align="center" gap={5}>
            <PromoIcon size={"32px"} color={promoIconColor} />
            <span>{addSpacingBetweenNumbersAndLetters(p?.promotion_description)}</span>
          </Flex>

          <div>
            <span>מחיר רגיל: </span>
            <span>{productData[store].item_price}</span>
            <span> שח </span>
            <span>ל- </span>
            <span>{productData[store].qty_in_package}</span>
            <span>&nbsp; </span>
            <span>{productData[store].unit_qty}</span>
          </div>

          <div>
            <span>בתוקף עד:</span>
            <span>{new Date(p.promotion_end_date).toLocaleDateString()}</span>
          </div>
        </div>
      );
    });
  }, [promoData, store, productData, promoIconColor]);

  if (!promoData?.length) return null;

  return (
    <div
      id={`promotion-card-${type}`}
      aria-expanded={isHovered}
      aria-label={typeof title === "string" ? title : "Promotion"}
      className={isHovered ? "scrollable" : ""}
      onMouseEnter={() => !alwaysShowData && setIsHovered(true)}
      onMouseLeave={() => !alwaysShowData && setIsHovered(false)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault(); // Prevent default action for space key
          setIsHovered(!isHovered); // Toggle visibility
        }
      }}
    >
      {isHovered ? generateDescription : title}
    </div>
  );
};

export default Promotion;
