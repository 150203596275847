import { possibleErrors } from "../../../components/ErrorPage/helpers/errorPageDict";
import { SiteContextType } from "../../../hooks/useCustomContext/useCustomContext";
import { CookieName, User } from "../../../types/types";
import { checkTokenExpIsValid, parseCookies, parseJwt } from "../../../utils/utils";
import { redirect } from "react-router-dom";

export const ProtectedRoute = async (_: any, contextValues: SiteContextType) => {
  const { user } = contextValues;

  if (!user.isUser) {
    alert(possibleErrors.membersOnly[user.language]);
    return redirect("/");
  }

  const token = parseCookies(CookieName.AuthUserData);

  if (!token) {
    alert(possibleErrors.expiredAction[user.language]);
    return redirect("/");
  }

  const decoded = parseJwt<User>(token);
  const isValid = checkTokenExpIsValid(decoded);

  if (!isValid) {
    console.log({ isValid, decoded, user, token });
    alert(possibleErrors.expiredAction[user.language]);
    return redirect("/");
  }

  return { user };
};
