import { useCallback, useEffect, useState } from "react";
import serverAPI from "../../../api/server";
import { Card, Flex } from "../../UI";
import useInfiniteScroll from "../../../hooks/useInfiniteScroll";
import { resolveImgUrl, uniqueArray } from "../../../utils/utils";
import OnlineStoresDict from "../../../utils/onlineStoresDict";

interface StoreData {
  store_id: number;
  img_url: string;
  item_code: string;
  item_name: string;
  sub_category_id: number;
}

// Metadata Interface
interface MetaData {
  category: string;
  category_id: number;
  sub_category: string;
  sub_category_id: number;
  score: number;
  user_id: number;
  same_sub_category: boolean;
  user_total: number;
  query_total: number;
  id: string;
}

// Final Data Structure
export interface UserProductsQueryResult {
  store_a: StoreData;
  store_b: StoreData;
  meta: MetaData;
}

const MatchingProducts = () => {
  const [data, setData] = useState<UserProductsQueryResult[]>([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [targetRef, isInView] = useInfiniteScroll({ threshold: 0.15 });

  const fetchData = useCallback(async () => {
    try {
      const res = await serverAPI(`users-products/matching`, "GET", {
        params: { from: data.length, size: 10 },
      });
      const uniqueData = uniqueArray<UserProductsQueryResult>([...data, ...res.data], (i) => i.meta.id);
      setData(uniqueData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [data.length]);

  useEffect(() => {
    const stop = data.length && data[0].meta.query_total === data.length;
    if ((!data.length || isInView) && !loading && !stop) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [isInView, loading]);

  return (
    <div>
      <div>
        <h1>Test</h1>
        <h3>Test ttt</h3>
      </div>
      <div>
        {data.map((r, idx) => {
          const storeA = OnlineStoresDict.onlineStoresDict[r.store_a.store_id].name;
          const storeB = OnlineStoresDict.onlineStoresDict[r.store_b.store_id].name;
          const isLastItem = idx === data.length - 1;
          return (
            <div key={idx} ref={isLastItem ? targetRef : null}>
              <Flex gap={2}>
                <Card title={r.store_a.item_name} subtitle={r.store_a.store_id}>
                  <div>{r.store_a.item_code}</div>
                  <div>{r.store_a.sub_category_id}</div>
                  <img src={resolveImgUrl(storeA, r.store_a.img_url)} alt="store_a" />
                </Card>
                <Card title={r.store_b.item_name} subtitle={r.store_b.store_id}>
                  <div>{r.store_b.item_code}</div>
                  <div>{r.store_b.sub_category_id}</div>
                  <img src={resolveImgUrl(storeB, r.store_b.img_url)} alt="store_b" />
                </Card>
              </Flex>
            </div>
          );
        })}
      </div>

      {loading && <p>Loading...</p>}
    </div>
  );
};

export default MatchingProducts;
