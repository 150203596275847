import { dictStores } from "../dictionary/dictStores";
import { Language, StoreEn, StoreObject, StoresDict } from "../types/types";

import victoryLogo from "../assets/images/stores_logos/victory.png";
import mahsaneLogo from "../assets/images/stores_logos/mahsane.png";
import bitanLogo from "../assets/images/stores_logos/bitan.png";
import carrefourLogo from "../assets/images/stores_logos/carrefour.png";
import ramiLogo from "../assets/images/stores_logos/rami.png";
import shufersalLogo from "../assets/images/stores_logos/shufersal.png";
import quickLogo from "../assets/images/stores_logos/quick.png";
class OnlineStoresDict {
  static onlineStoresDict: StoresDict = {
    1: { name: "victory", logo: victoryLogo },
    2: { name: "mahsane", logo: mahsaneLogo },
    3: { name: "bitan", logo: bitanLogo },
    // {name:4: "mega",logo:""},
    5: { name: "carrefour", logo: carrefourLogo },
    6: { name: "rami", logo: ramiLogo },
    7: { name: "shufersal", logo: shufersalLogo },
    8: { name: "quick", logo: quickLogo },
  };

  static getStores = (language: Language) => {
    return Object.entries(this.onlineStoresDict).map((entry) => ({
      id: entry[0],
      ...entry[1],
      label: dictStores[entry[1].name][language],
    }));
  };

  static getOnlineStoreIdFromName = (onlineStore: StoreEn) => {
    for (const [id, store] of Object.entries(this.onlineStoresDict)) {
      if (store.name === onlineStore) {
        return parseInt(id);
      }
    }
    return 0;
  };

  static convertOnlineStoreToObj = (store: StoreEn): StoreObject => {
    return { name: store, id: this.getOnlineStoreIdFromName(store) };
  };

  static convertOnlineStoresToArrOfObj = (otherStores: StoreEn[]): StoreObject[] => {
    return otherStores.map(this.convertOnlineStoreToObj);
  };
}

export default OnlineStoresDict;
