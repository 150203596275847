import React from "react";
import { ComingSoon } from "../../UI";

const MyShoppingData = () => {
  return (
    <div>
      <ComingSoon description="אנו עושים את המיטב ועדיין מפתחים עבורכם">היסטוריית קניות</ComingSoon>
    </div>
  );
};

export default MyShoppingData;
