import { Dispatch, FC, SetStateAction, useMemo, useState } from "react";
import { Cart, Language, StoreEn } from "../../../types/types";
import { dictStores } from "../../../dictionary/dictStores";
import { Card, Flex, InfoBox, Modal, Tooltip, InputUI } from "../../UI";
import "./loginToStore.css";
import { dictAuth } from "../../Authentication/dictionary";
import { dictButtonsAndActions } from "../../../dictionary/dictButtons";
import { EmailIcon, InfoIcon, PasswordHideIcon } from "../../UI/Icons";
import serverAPI from "../../../api/server";
import { UsersProductsEP } from "../../../api/features/usersProducts.ep.types";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import OnlineStoresDict from "../../../utils/onlineStoresDict";
import PriceCalculator from "../../../models/ItemPriceCalculator/PriceCalculator";
import { getNormalizingStorePrice } from "../../../pages/CartComparisonPage/Helpers/Utils";

type Props = {
  store: StoreEn;
  isOpen: boolean;
  language: Language;
  cart: Cart;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const LoginToStoreModal: FC<Props> = ({ store, isOpen = false, language, cart, setIsOpen }) => {
  const {
    onlineStores: { favoriteStore },
  } = useCustomContext();
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const dict = useMemo(() => {
    return {
      title: {
        hebrew: (store: StoreEn) => `התחברות ל${dictStores[store].hebrew}`,
        english: (store: StoreEn) => `Login To ${dictStores[store].english}`,
        russian: (store: StoreEn) => `Вход в ${dictStores[store].english}`,
        arabic: (store: StoreEn) => `تسجيل الدخول إلى ${dictStores[store].english}`,
      },
      description: {
        hebrew: (store: StoreEn, language: Language) => {
          return (
            <>
              הפרטים שלך ישמשו להעברת העגלה בלבד. אנו מחויבים להגנה על פרטיותך. פרטי ההתחברות שלך ל
              {dictStores[store][language]} אינם נשמרים במערכת.
            </>
          );
        },
        english: (store: StoreEn, language: Language) => {
          return (
            <>
              <>
                <p>
                  We want to save you time by copying the cart content to your selected store's cart. We only need your{" "}
                  {dictStores[store][language]} credentials to log in to your account.{" "}
                  <b>We do not store your credentials anywhere</b>; we simply fill the cart for you.
                </p>
                <p>
                  <b>Pay attention:</b> This operation is only available for one store. If you choose to proceed, it may
                  take some time and will empty your current cart.
                </p>
              </>
            </>
          );
        },
        russian: (store: StoreEn, language: Language) => {
          return (
            <>
              <p>
                Мы хотим сэкономить ваше время, скопировав содержимое корзины в корзину выбранного вами магазина. Нам
                нужны только ваши учетные данные {dictStores[store][language]}, чтобы войти в ваш аккаунт.{" "}
                <b>Мы не сохраняем ваши учетные данные нигде</b>; мы просто заполняем корзину для вас.
              </p>
              <p>
                <b>Обратите внимание:</b> Эта операция доступна только для одного магазина. Если вы решите продолжить,
                это может занять некоторое время и приведет к очистке вашей текущей корзины.
              </p>
            </>
          );
        },
        arabic: (store: StoreEn, language: Language) => {
          return (
            <>
              <p>
                نرغب في توفير وقتك عن طريق نسخ محتوى السلة إلى سلة المتجر الذي اخترته. نحتاج فقط إلى بيانات تسجيل الدخول
                الخاصة بك في متجر {dictStores[store][language]} لتسجيل الدخول إلى حسابك.{" "}
                <b>نحن لا نقوم بتخزين بيانات الدخول الخاصة بك في أي مكان</b>؛ نقوم ببساطة بملأ السلة بالنسبة لك.
              </p>
              <p>
                <b>يرجى الملاحظة:</b> هذه العملية متاحة فقط لمتجر واحد. إذا اخترت المتابعة، قد يستغرق الأمر وقتًا وسيتم
                تفريغ سلة التسوق الحالية.
              </p>
            </>
          );
        },
      },
    };
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // admin update user products faster
    if (event.ctrlKey && event.key === "q") {
      console.log("Key Down Detected sending request to server from func:customUserProductsUpdate");
      customUserProductsUpdate();
      event.preventDefault();
    }
  };

  // Update user products table if user replace/find new item per current store
  const customUserProductsUpdate = () => {
    // no need to do nothing if we on favorite
    if (store === favoriteStore) return;

    const customProductsBody: UsersProductsEP["user-products/update/custom"]["body"] = [];

    Object.entries(cart).forEach(([itemCode, productData]) => {
      const itemData = productData.product[store];

      if (itemData?.source === "FE-user-select") {
        const favoriteStoreId = OnlineStoresDict.getOnlineStoreIdFromName(favoriteStore);
        const storeId = OnlineStoresDict.getOnlineStoreIdFromName(store);
        const storeProductItemCode = itemData.item_code;
        customProductsBody.push({
          subCategory: itemData.sub_category,
          favoriteStoreId,
          storeId,
          favoriteProductItemCode: itemCode,
          storeProductItemCode,
        });
      }
    });
    if (customProductsBody.length) serverAPI("user-products/update/custom", customProductsBody);
  };

  const handleTransferCart = async () => {
    try {
      setIsLoading(true);
      // Update userproducts
      customUserProductsUpdate();
      const cartValues = Object.values(cart)
        .filter((p) => p.product[store])
        .map((p) => {
          const id = parseInt(p.product[store].retailer_product_id);
          const type = p.product[store].item_type;
          const quantity = p.amount;
          return { id, quantity, type, soldBy: null };
        });
      const res = await serverAPI("cart/checkout/transfer-to-store", { store, lines: cartValues, credentials });
      // TODO : add custom user products to user products table
      // TODO : Add normalizing store cart to favorite cart

      if (res.statusCode !== 200) throw new Error(res.data);
    } catch (error: any) {
      setMessage(error.message || error.data);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCredentials((prev) => ({ ...prev, [id]: value }));
  };

  const storeVsStorePrice = () => {
    if (store === favoriteStore) return null;

    const currentStoreCartPrice = `₪${PriceCalculator.getTotalCartCost(store, cart).toFixed(2)}`;
    const favoriteStoreCartPrice = `₪${getNormalizingStorePrice(store, favoriteStore, cart, "string")}`;

    const favoriteName = dictStores[favoriteStore][language];
    const storeName = dictStores[store][language];

    return (
      <Flex flexDirection="column">
        <Card variantColor="blue" dataTour="store-vs-store">
          <Flex justify="space-around">
            <Flex flexDirection="column" align="center" gap={2}>
              <span style={{ fontWeight: "bold", color: "#0056b3" }}>{favoriteStoreCartPrice}</span>
              <Flex>
                <Tooltip
                  placement="bottom"
                  title={
                    <div
                      style={{ width: 200, textAlign: "center" }}
                    >{`העלות המוצגת כאן עבור ב${favoriteName} מחושבת רק עבור פריטים שקיימים ב${storeName}`}</div>
                  }
                >
                  <InfoIcon />
                </Tooltip>

                <span>{favoriteName}</span>
              </Flex>
            </Flex>
            <Flex flexDirection="column" align="center" gap={2}>
              <span style={{ fontWeight: "bold", color: "#0056b3" }}>{currentStoreCartPrice}</span>
              <span>{storeName}</span>
            </Flex>
          </Flex>
        </Card>
      </Flex>
    );
  };

  return (
    <Modal
      style={{ backgroundColor: "var(--white)" }}
      loading={isLoading}
      language={language}
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleTransferCart}
      title={dict.title[language](store)}
      okText={dictButtonsAndActions.ok[language]}
      cancelText={dictButtonsAndActions.cancel[language]}
    >
      <Flex
        onKeyDown={handleKeyDown}
        tabIndex={0}
        flexDirection="column"
        gap={35}
        style={{ width: "95%", margin: "auto", minWidth: 300, maxWidth: 450, padding: 10 }}
      >
        {storeVsStorePrice()}

        <Flex dataTour="login-to-store-form" flexDirection="column" gap={20} style={{ paddingTop: 4 }}>
          <Flex flexDirection="column" gap={10}>
            <span>{`הזינו פרטי ההתחברות שלכם לאתר של ****`}</span>
            <InputUI
              placeholder={dictAuth.email[language]}
              type="email"
              id="email"
              required
              icon={<EmailIcon />}
              onChange={handleInputChange}
              value={credentials.email}
            />
            <InputUI
              value={credentials.password}
              onChange={handleInputChange}
              placeholder={dictAuth.password[language]}
              type="password"
              id="password"
              required
              icon={<PasswordHideIcon clickable={false} />}
            />
          </Flex>

          <Flex flexDirection="column" gap={5}>
            <InfoBox
              style={{ fontSize: "var(--font-size-sm)" }}
              content={dict.description[language](store, language)}
              type="info"
              language={language}
            />

            <InfoBox
              style={{ fontSize: "var(--font-size-sm)", opacity: message ? 1 : 0 }}
              content={message}
              type="error"
              language={language}
            />
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default LoginToStoreModal;
