import { useState, FC, memo, useCallback } from "react";
import { Language } from "../../../types/types";
import "./Departments.css";
import { Link } from "react-router-dom";
import { IconCustom } from "../../UI/Icons/types";
import { directionByLanguage } from "../../../utils/utils";
import CategoriesMap from "../../../assets/Data/categories";

type Props = {
  language: Language;
};

const DepartmentsNav: FC<Props> = ({ language }) => {
  const [hoveredIcon, setHoveredIcon] = useState(-1);
  const [selectedDep, setSelectedDep] = useState(-1);
  const [ddPosition, setddPosition] = useState({ width: 0, left: 0 });

  const handleMouseEnter = (e: any, departmentKey: number) => {
    const { left, width } = e.target.getBoundingClientRect();
    setddPosition({ width, left });
    setHoveredIcon(departmentKey);
  };

  const handleMouseLeave = () => {
    setHoveredIcon(-1);
  };

  const positionOffset = useCallback(
    (idx: number, categoryLength: number) => {
      const width = window.innerWidth;
      if (width < 500) return 100 - ddPosition.left;

      let offset = 0;

      if (idx === categoryLength - 1) offset = ddPosition.width / 2;
      else if (idx === 0) offset = -ddPosition.width / 2;

      if (directionByLanguage(language) === "ltr") return -offset;

      return offset;
    },
    [ddPosition, language]
  );

  const drawDepartments = useCallback(() => {
    return CategoriesMap.map((d, idx) => {
      const Icon: FC<IconCustom> = d.icon;
      return (
        <ul
          key={d.id}
          className={"nav-department-wrapper"}
          onMouseEnter={(e) => handleMouseEnter(e, d.id)}
          onMouseLeave={handleMouseLeave}
        >
          <li className="responsive-marginRL-10">{<Icon hovered={hoveredIcon === d.id || selectedDep === d.id} />}</li>
          <li className={`responsive-hidden-lg ${selectedDep === d.id ? "primary-contrast-color" : ""}`}>{d.hebrew}</li>

          <ul
            className={`dd-categories-list`}
            style={{
              left: `calc( ${ddPosition.left + positionOffset(idx, CategoriesMap.length)}px )`,
              transform: `translateX(-25%)`,
            }}
          >
            {d.sub_categories
              .sort((a, b) => a.id - b.id)
              .map((cat) => {
                return (
                  <Link key={cat.id} to={`/products/${cat.id}`}>
                    <li onClick={() => setSelectedDep(cat.id)}>{cat[language]}</li>
                  </Link>
                );
              })}
          </ul>
        </ul>
      );
    });
  }, [ddPosition, selectedDep, hoveredIcon, positionOffset, language]);

  // TODO: in settings lets user decide if he want the navigation bar responsive-hidden
  return <nav className={`department_nav_container scrollable container responsive-hidden`}>{drawDepartments()}</nav>;
};

export default memo(DepartmentsNav);
