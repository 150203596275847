import { Dispatch, SetStateAction } from "react";
import serverAPI from "../../../api/server";
import { StoreEn } from "../../../types/types";
import OnlineStoresDict from "../../../utils/onlineStoresDict";

type Args = {
  subCategory: number;
  favoriteStore: StoreEn;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
};

const fetchProductsV2 = async ({ subCategory, favoriteStore, setIsLoading }: Args) => {
  try {
    setIsLoading(() => true);
    const favoriteStoreObj = OnlineStoresDict.convertOnlineStoreToObj(favoriteStore);
    const { data, statusCode } = await serverAPI(`products/common/v2/get`, "GET", {
      params: { subCategory, store: favoriteStoreObj.name, id: favoriteStoreObj.id },
    });
    if (statusCode === 200 && data.length) {
      return data;
    } else {
      // TODO : create popup that explain
      return [];
    }

    // todo if === 401 popup login
  } catch (e: any) {
    console.log(e);
    return [];
    // throw new Response("xxx");
  } finally {
    setIsLoading(() => false);
    // console.log("fetch products");
  }
};

export default fetchProductsV2;
