import { useCallback, useRef, useState } from "react";

const useInfiniteScroll = (options?: IntersectionObserverInit): [(node: HTMLDivElement | null) => void, boolean] => {
  const [isInView, setIsInView] = useState(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const targetRef = useCallback(
    (node: HTMLDivElement | null) => {
      // Clean up previous observer
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      if (node) {
        // Create and attach a new observer
        observerRef.current = new IntersectionObserver(([entry]) => setIsInView(entry.isIntersecting), options);
        observerRef.current.observe(node);
      }
    },
    [options]
  );

  return [targetRef, isInView];
};

export default useInfiniteScroll;
