import { ReactNode, useState } from "react";
import "./Accordion.css";

export type AccordionItem = {
  key: string;
  label: ReactNode | string;
  children: string[] | ReactNode[];
};

const Accordion = ({ items = [] }: { items?: AccordionItem[] }) => {
  const [open, setOpen] = useState<string | null>(null);

  const handleClick = (key: string) => {
    if (key === open) setOpen(null);
    else setOpen(key);
  };

  const drawItemChildren = (children: AccordionItem["children"], isOpen: boolean) => {
    return children.map((c, idx) => {
      return (
        <div onClick={(e) => e.stopPropagation()} key={idx} className="accordion-submenu-link">
          {isOpen ? c : null}
        </div>
      );
    });
  };

  return (
    <ul className="accordion-wrapper">
      {items.map((item) => {
        return (
          <li
            key={item.key}
            className="accordion-item"
            onClick={() => {
              handleClick(item.key);
            }}
          >
            <div className="accordion-item-title">{item.label}</div>

            <div id={item.key} className={open === item.key ? "accordion-item-submenu-open" : "accordion-item-submenu"}>
              {drawItemChildren(item.children, open === item.key)}
            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default Accordion;
