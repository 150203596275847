import { FC } from "react";
import {
  BabyPharm,
  Bakery,
  CerealBreakfast,
  Cleaning,
  Deli,
  DiaryAndEggs,
  DietHealth,
  FreshProduceIcon,
  Frozen,
  Grocery,
  House,
  MeatChickenFish,
  Sweets,
  Wine,
} from "../../components/UI/Icons/DepartmentsIcons";

import { IconCustom } from "../../components/UI/Icons/types";

export type SubCategory = {
  id: number;
  arabic: string;
  hebrew: string;
  english: string;
  russian: string;
};

export interface CategoriesAndSub {
  id: number;
  icon: FC<IconCustom>;
  arabic: string;
  hebrew: string;
  english: string;
  russian: string;
  sub_categories: SubCategory[];
}

const CategoriesMap: CategoriesAndSub[] = [
  {
    id: 1,
    icon: FreshProduceIcon,
    hebrew: "ירקות ופירות",
    english: "Fresh Produce",
    russian: "Фрукты и овощи",
    arabic: "المنتجات الطازجة",
    sub_categories: [
      { id: 79705, arabic: "فواكه", hebrew: "פירות", english: "Fresh Fruit", russian: "Свежие фрукты" },
      { id: 79706, arabic: "خضروات", hebrew: "ירקות", english: "Fresh Vegetables", russian: "Свежие овощи" },
      {
        id: 79707,
        arabic: "فواكه مجففة ومكسرات",
        hebrew: "פירות יבשים ואגוזים",
        english: "Dried Fruit & Nut",
        russian: "Сухофрукты",
      },
      {
        id: 79708,
        arabic: "صواني تقديم وسندويشات",
        hebrew: "מגשי אירוח וסנדוויצ'ים ",
        english: "Platters & Sandwiches",
        russian: "Подносы с закусками и сэндвичи",
      },
    ],
  },
  {
    id: 2,
    icon: DiaryAndEggs,
    hebrew: "מוצרי חלב וביצים",
    english: "Dairy  & Eggs",
    russian: "Молочное и яйца",
    arabic: "منتجات الألبان والبيض",
    sub_categories: [
      { id: 79720, arabic: "بيض", hebrew: "ביצים", english: "Eggs", russian: "Яйца" },
      {
        id: 95010,
        arabic: "حليب ومشروبات الحليب",
        hebrew: "חלב ומשקאות חלב",
        english: "Milk & Cream",
        russian: "Молоко и сливки",
      },
      {
        id: 95012,
        arabic: "أجبان وزبدة",
        hebrew: "גבינות וחמאה",
        english: "Cheese & Butter",
        russian: "Сыр и масло",
      },
      {
        id: 95011,
        arabic: "لبن وحلويات",
        hebrew: "יוגורט ומעדנים",
        english: "Yogurt & Puddings",
        russian: "Йогурты и десерты",
      },
      {
        id: 95809,
        arabic: "لبن وقشدة",
        hebrew: "לבן אשל ושמנת",
        english: "Heavy Cream & Sour Cream",
        russian: "Простокваша и сметана",
      },
      {
        id: 95013,
        arabic: "منتجات عجين ومعكرونة مبردة",
        hebrew: "מוצרי בצק ופסטה בקירור",
        english: "Refrigerated",
        russian: "Холодильник",
      },
    ],
  },
  {
    id: 3,

    icon: CerealBreakfast,
    hebrew: "דגנים",
    english: "Cereal & Breakfast",
    russian: "Сухой завтрак",
    arabic: "الحبوب ووجبات الإفطار",
    sub_categories: [
      { id: 79732, arabic: "حبوب الإفطار", hebrew: "דגני בוקר", english: "Cereal", russian: "Сухой завтрак" },
      { id: 79733, arabic: "حبوب", hebrew: "דגנים", english: "Breakfast", russian: "Злаки" },
      {
        id: 95814,
        arabic: "وجبات خفيفة من الحبوب والطاقة",
        hebrew: "חטיפי דגנים ואנרגיה",
        english: "Cereal & Energy Bars",
        russian: "Батончики",
      },
    ],
  },
  {
    id: 4,
    icon: MeatChickenFish,
    hebrew: "עוף בשר ודגים",
    english: "Meat, Chicken & Fish",
    russian: "Мясо, рыба и птица",
    arabic: "اللحوم والدجاج والأسماك",
    sub_categories: [
      {
        id: 79822,
        arabic: "دجاج وديك رومي طازج",
        hebrew: "עוף והודו טרי",
        english: "Chicken & Turkey",
        russian: "Свежая птица",
      },
      {
        id: 93709,
        arabic: "دجاج وديك رومي مجمد",
        hebrew: "עוף והודו קפוא",
        english: "Frozen Chicken & Turkey",
        russian: "Замороженная птица",
      },
      { id: 79823, arabic: "لحم طازج", hebrew: "בשר טרי", english: "Meat", russian: "Свежее мясо" },
      {
        id: 93710,
        arabic: "لحم مجمد",
        hebrew: "בשר קפוא",
        english: "Frozen Meat",
        russian: "Замороженное мясо",
      },
      { id: 79824, arabic: "أسماك", hebrew: "דגים", english: "Fish", russian: "Рыба" },
    ],
  },
  {
    id: 5,
    hebrew: "שימורים בישול ואפיה",
    english: "Grocery",
    icon: Grocery,
    russian: "Бакалея",
    arabic: "منتجات البقالة",
    sub_categories: [
      {
        id: 79623,
        arabic: "معلبات وصلصات",
        hebrew: "שימורים ורטבים",
        english: "Canned Food & Sauces",
        russian: "Консервация и соусы",
      },
      {
        id: 79620,
        arabic: "عسل ومربيات ودهنات",
        hebrew: "דבש, ריבות, ממרחים",
        english: "Honey, Jams & Spreads",
        russian: "Мёд, варенье и спреды",
      },
      {
        id: 79621,
        arabic: "منتجات أساسية وتوابل",
        hebrew: "מוצרי יסוד ותבלינים",
        english: "Basic Products & Spices",
        russian: "Основные продукты и специи",
      },
      {
        id: 796224,
        arabic: "منتجات الخبز والطهي",
        hebrew: "מוצרי אפיה ובישול",
        english: "Baking Needs & Cooking Essentials",
        russian: " Выпечка и кулинария",
      },
    ],
  },
  {
    id: 6,
    icon: Deli,
    hebrew: "מעדניה סלטים ונקניקים",
    english: "Deli & Salads  ",
    russian: "Деликатесы и салаты",
    arabic: "وجبات لذيذة وسلطات",
    sub_categories: [
      {
        id: 79605,
        arabic: "سلطات معبأة",
        hebrew: "סלטים ארוזים",
        english: "Packaged Salads",
        russian: "Фасованные салаты",
      },
      {
        id: 79604,
        arabic: "نقانق وبسطرمة",
        hebrew: "נקניקים ופסטרמות",
        english: "Packaged Salami & Pastrami",
        russian: "Колбасные изделия",
      },
      { id: 79606, arabic: "معلبات", hebrew: "מעדנייה", english: "Deli", russian: "Гастроном" },
      {
        id: 95017,
        arabic: "طعام جاهز من المعلبات",
        hebrew: "אוכל מוכן מעדנייה",
        english: "Prepared Foods",
        russian: "Готовые блюда",
      },
      {
        id: 81224,
        arabic: "نقانق وبسطرمة بالوزن",
        hebrew: "נקניקים ופסטרמות במשקל",
        english: "Fresh Cut Salami & Pastrami",
        russian: "Колбасы и нарезки на развес",
      },
    ],
  },
  {
    id: 7,
    icon: Bakery,
    hebrew: "לחמים עוגות ועוגיות",
    english: "Bakery",
    russian: "Пекарня",
    arabic: "المخبوزات",
    sub_categories: [
      { id: 79689, arabic: "خبز", hebrew: "לחם", english: "Bread", russian: "Хлеб" },
      {
        id: 79688,
        arabic: "كعك وبسكويت",
        hebrew: "עוגות ועוגיות",
        english: "Bakery",
        russian: "Сдобные изделия",
      },
      {
        id: 79690,
        arabic: "كراكر ورقائق",
        hebrew: "קרקר, פריכיות ופתית",
        english: "Rice Cakes & Crackers",
        russian: "Крекеры",
      },
      { id: 96550, arabic: "مخبز", hebrew: "מאפיה", english: "Bakery", russian: "пекарня" },
    ],
  },
  {
    id: 8,
    icon: Frozen,
    hebrew: "קפואים",
    english: "Frozen",
    russian: "Морозилка",
    arabic: "مجمدات",
    sub_categories: [
      {
        id: 79593,
        arabic: "أسماك مجمدة",
        hebrew: "דגים קפואים",
        english: " All Frozen",
        russian: "Замороженные товары",
      },
      {
        id: 95816,
        arabic: "عجين",
        hebrew: "בצקים",
        english: "Frozen Pastry Goods",
        russian: "Продукция из теста",
      },
      {
        id: 95824,
        arabic: "فاكهة وخضروات مجمدة",
        hebrew: "פירות וירקות קפואים",
        english: "Frozen Fruit and Vegetables",
        russian: "Замороженные фрукты и овощи",
      },
      {
        id: 79592,
        arabic: "مثلجات",
        hebrew: "גלידה וקרחונים",
        english: "Ice Cream & Ices",
        russian: "Мороженое",
      },
      {
        id: 95828,
        arabic: "طعام جاهز مجمد",
        hebrew: "אוכל מוכן קפוא",
        english: "Ready Made Foods",
        russian: "Готовая продукция",
      },
    ],
  },
  {
    id: 9,
    icon: Sweets,
    hebrew: "חטיפים וממתקים",
    english: "Sweets & Snacks",
    russian: "Сладости, чипсы и снеки",
    arabic: "حلويات ووجبات خفيفة",
    sub_categories: [
      { id: 79654, arabic: "وجبات خفيفة", hebrew: "חטיפים", english: "Snacks", russian: "Снеки" },
      { id: 79655, arabic: "حلويات", hebrew: "ממתקים", english: "Candy & Sweets", russian: "Сладости" },
    ],
  },
  {
    id: 10,
    icon: Wine,
    hebrew: "משקאות ויין",
    english: "Beverages & Wine",
    russian: "Напитки и алкоголь",
    arabic: "مشروبات ونبيذ",
    sub_categories: [
      {
        id: 79670,
        arabic: "مشروبات غازية",
        hebrew: "משקאות קלים",
        english: "Soft Drinks",
        russian: "Лёгкие напитки",
      },
      {
        id: 79668,
        arabic: "مشروبات ساخنة",
        hebrew: "משקאות חמים",
        english: "Hot Drinks",
        russian: "Горячие напитки",
      },
      {
        id: 79669,
        arabic: "نبيذ وكحول",
        hebrew: "יינות ואלכוהול",
        english: "Wine & Alcohol",
        russian: "Вина и алкоголь",
      },
    ],
  },
  {
    id: 11,
    icon: BabyPharm,
    hebrew: "פארם ותינוקות",
    english: "Beauty & Baby",
    russian: "Косметика и уход",
    arabic: "الصحة والتجميل",
    sub_categories: [
      { id: 79572, arabic: "استحمام", hebrew: "רחצה", english: "Bath", russian: "Для душа" },
      {
        id: 95186,
        arabic: "العناية بالفم",
        hebrew: "היגיינת הפה",
        english: "Oral Care",
        russian: "Гигиена полости рта",
      },
      {
        id: 79574,
        arabic: "منتجات النظافة",
        hebrew: "מוצרי היגיינה",
        english: "Hygiene",
        russian: "Гигиена",
      },
      {
        id: 79573,
        arabic: "عطور",
        hebrew: "פרפומריה ",
        english: "Personal Care",
        russian: "Косметика и парфюмерия",
      },
      {
        id: 95797,
        arabic: "النظافة للرضع",
        hebrew: "היגיינה לתינוק",
        english: "Baby Hygiene",
        russian: "Детская гигиена",
      },
      {
        id: 95798,
        arabic: "طعام للرضع",
        hebrew: "מזון לתינוק",
        english: "Baby Food",
        russian: "Детское питание",
      },
      {
        id: 95807,
        arabic: "إكسسوارات للرضع",
        hebrew: "אביזרים לתינוק",
        english: "Baby Accessories",
        russian: "Аксессуары",
      },
    ],
  },
  {
    id: 12,
    hebrew: "בריאות ותזונה",
    icon: DietHealth,
    english: "Health & Specialty Diets",
    russian: "Здоровое питание",
    arabic: "الصحة والأنظمة الغذائية المتخصصة",
    sub_categories: [
      {
        id: 94570,
        arabic: "بدائل الحليب",
        hebrew: "תחליפי חלב",
        english: "Dairy Substitutes",
        russian: "Растительные напитки",
      },
      {
        id: 94571,
        arabic: "بدائل اللحوم",
        hebrew: "תחליפי בשר",
        english: "Meat Substitutes",
        russian: "Заменители мяса",
      },
      {
        id: 79840,
        arabic: "بدون جلوتين",
        hebrew: "ללא גלוטן",
        english: "Gluten Free",
        russian: "Безглютеновая продукция",
      },
      {
        id: 87293,
        arabic: "فيتامينات ومكملات",
        hebrew: "ויטמינים ותוספים",
        english: "Vitamins & Supplements",
        russian: "Витамины и добавки",
      },
      {
        id: 94589,
        arabic: "تغذية خاصة",
        hebrew: "תזונה מיוחדת",
        english: "Special Diets",
        russian: "Специализированное питание",
      },
      {
        id: 94568,
        arabic: "دايت وبدون سكر",
        hebrew: "דיאט וללא סוכר",
        english: "Sugar Free",
        russian: "Продукция без добавления сахара",
      },
    ],
  },
  {
    id: 13,
    icon: Cleaning,
    hebrew: "ניקיון",
    english: "Cleaning",
    russian: "Бытовая химия",
    arabic: "نظافة",
    sub_categories: [
      {
        id: 79742,
        arabic: "مواد تنظيف المنزل",
        hebrew: "חומרי ניקוי לבית",
        english: "Household Cleaning",
        russian: "Всё для уборки",
      },
      {
        id: 79741,
        arabic: "غسيل وكوي",
        hebrew: "כביסה וגיהוץ",
        english: "Laundry & Ironing",
        russian: "Всё для стирки",
      },
      {
        id: 79743,
        arabic: "إكسسوارات تنظيف",
        hebrew: "אביזרי ניקיון",
        english: "Cleaning Accessories",
        russian: "Хозяйственные товары",
      },
      {
        id: 79744,
        arabic: "ورق تواليت ومناديل ومناشف مبللة",
        hebrew: "נייר טואלט, טישו ומגבונים לחים",
        english: "Tissue & Paper Towels",
        russian: "Туалетная бумага и салфетки",
      },
    ],
  },
  {
    id: 14,
    icon: House,
    hebrew: "הכל לבית",
    english: "Houseware & Leisure",
    russian: "Товары для дома и отдыха",
    arabic: "أدوات منزلية وترفيه",
    sub_categories: [
      {
        id: 79769,
        arabic: "منتجات للاستعمال مرة واحدة",
        hebrew: "חד פעמי",
        english: "Disposables",
        russian: "Одноразовая посуда",
      },
      {
        id: 79768,
        arabic: "منتجات منزلية",
        hebrew: "מוצרים לבית",
        english: "Home Care",
        russian: "Инвентарь для дома",
      },
      { id: 79770, arabic: "نسيج", hebrew: "טקסטיל", english: "Textiles", russian: "Текстиль" },
      {
        id: 98800,
        arabic: "زينة للأعياد",
        hebrew: "קישוטים לחג",
        english: "Festive Decorations",
        russian: "Праздничные украшения",
      },
      { id: 122374, arabic: "كهرباء", hebrew: "חשמל", english: "Electronics", russian: "Электроника" },
    ],
  },
  {
    id: 15,
    icon: House, //todo:create new icon
    hebrew: "פנאי ובעלי חיים",
    english: "Leisure & Pets",
    russian: "Досуг и домашние животные\n\n\n\n\n\n\n\n",
    arabic: "الترفيه والحيوانات الأليفة\n\n\n\n\n\n\n\n",
    sub_categories: [
      { id: 122844, arabic: "حيوانات أليفة", hebrew: "בעלי חיים", english: "Pets", russian: "Животные" },
      {
        id: 79808,
        arabic: "أعياد الميلاد",
        hebrew: "ימי הולדת",
        english: "Birthdays",
        russian: "Дни рождения",
      },
      { id: 122845, arabic: "ترفيه", hebrew: "פנאי", english: "Leisure", russian: "Досуг" },
    ],
  },
];

export default CategoriesMap;
