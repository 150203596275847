import { memo, FC, useCallback } from "react";
import "./CartContent.css";
import { Button, Tooltip } from "../../UI";
import { Language, StoreEn } from "../../../types/types";
import { useNavigate } from "react-router-dom";
import CartHeader from "../CartHeader/CartHeader";
import { SortCartByCategories } from "./helper/SortCartByCategories";
import { DrawCartFooterLine } from "../../../pages/CartComparisonPage/Helpers/Ui";
import drawCartItems from "./helper/drawCartItems";
import useLocalStorage, { localStorageKeys } from "../../../hooks/useLocalStorage";
import { useCartStore } from "../../../Zustand";

type Props = {
  // cart: Cart;
  favoriteStore: StoreEn;
  cartTotalPriceRange: string;
  showHeader?: boolean;
  language: Language;
};

export type cartSortingMethods = "order" | "categories" | "abc";

const CartContent: FC<Props> = ({ favoriteStore, cartTotalPriceRange, showHeader = true, language }) => {
  const { cart, isCartOpen, setIsCartOpen } = useCartStore();

  const navigate = useNavigate();
  const [sortingMethod, setSortingMethod] = useLocalStorage(localStorageKeys.cartSortingMethod, "abc");

  const drawCartItemsByOrder = useCallback(() => {
    let itemsKeys = Object.keys(cart);

    if (!isCartOpen) return null;

    switch (sortingMethod) {
      case "order":
        return drawCartItems(itemsKeys.reverse(), cart, favoriteStore);
      case "abc":
        return drawCartItems(
          itemsKeys.sort((a, b) =>
            cart[a].product[favoriteStore].item_name.localeCompare(cart[b].product[favoriteStore].item_name)
          ),
          cart,
          favoriteStore
        );
      case "categories":
        return <SortCartByCategories cart={cart} store={favoriteStore} language={language} />;
      default:
        return null;
    }
  }, [sortingMethod, cart, favoriteStore, language, isCartOpen]);

  const handleProceedToCheckOut = async () => {
    // TODO: This function should be after payment and not before
    //! IMPORTANT : WE CHANGE THE SOURCE LOGIC , NEED TO HANDLE THIS API LATER
    // const customProducts = resolveCustomProductsFromCart(favoriteStore, otherStores, cart);
    // if (customProducts.length) serverAPI("usersProducts/update/custom", customProducts);
    console.log("make api createOrUpdate");

    navigate("/cart/compare");
  };

  return (
    <div id="cart-container">
      {showHeader && (
        <CartHeader
          setSortingMethod={setSortingMethod}
          sortingMethod={sortingMethod}
          store={favoriteStore}
          onClose={() => setIsCartOpen(false)}
        />
      )}
      <div className="cart-items-wrapper">{drawCartItemsByOrder()}</div>
      <div className="cart-summarize">
        <div className="cart-total-box">
          <DrawCartFooterLine description={'סה"כ'} price={cartTotalPriceRange} />

          <Tooltip title={Object.keys(cart).length >= 3 ? null : "מלאו לפחות 3 פריטים..."}>
            <Button
              variant="contrast"
              disabled={Object.keys(cart).length <= 3}
              onClick={handleProceedToCheckOut}
              width={220}
            >
              השוואת מחירים
            </Button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default memo(CartContent);
