import "./Slider.css";
interface SliderProps {
  min: number;
  max: number;
  step?: number;
  value: number;
  onChange: (value: number) => void;
  id?: string;
}

const Slider: React.FC<SliderProps> = ({ min, max, step = 1, value, onChange, id }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(Number(e.target.value));
  };

  const percentage = ((value - min) / (max - min)) * 100;

  return (
    <div className="ui-single-slider-wrapper">
      <div className="slider-track">
        <div className="slider-range" style={{ width: `${percentage}%` }}></div>
        <div className="slider-thumb" style={{ left: `${percentage}%` }}></div>
      </div>
      <input id={id} type="range" min={min} max={max} step={step} value={value} onChange={handleChange} />
    </div>
  );
};

export default Slider;
