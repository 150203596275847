import { FC } from "react";
import { FrontTable, StoreEn } from "../../../types/types";
import "./SelectedItemImages.css";
import { ImageComponent } from "../../UI";

type Props = {
  front: { [store in StoreEn]: FrontTable };
  stores: StoreEn[];
  currentStore: StoreEn;
};

const SelectedItemImages: FC<Props> = ({ front, stores, currentStore }) => {
  const drawImages = () => {
    const src = front[currentStore]?.img_url || require("../../../assets/images/Image_not_available.png");
    return <ImageComponent width={"100%"} src={src} alt={front[stores[0]]?.item_name_front} />;
  };

  return <div className="selected-zoom-img">{drawImages()}</div>;
};

export default SelectedItemImages;
