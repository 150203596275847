import React from "react";
import "./ComingSoon.css";

interface IComingSoonProps {
  description: string;
  children: any;
}

const ComingSoon = ({ description, children }: IComingSoonProps) => {
  return (
    <div className="coming-soon_container">
      <div className="coming-soon_overlay">
        <div className="coming-soon_info">
          <span className="coming-soon_badge">בקרוב</span>
          <h3 className="coming-soon_title">{children}</h3>
          <p className="coming-soon_description">{description}</p>
        </div>
      </div>
    </div>
  );
};
export default ComingSoon;
