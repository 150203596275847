import { FC, useState } from "react";
import "./ProductCard.css";
import { ProductDataType } from "../../types/types";

import ProductCardGeneric from "./ProductCardLayoutVersions/ProductCardGeneric";
import { ProductCardBodyV2, ProductCardFooterV2 } from "./ProductCardLayoutVersions/ProductCardV2";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import { resolveImgUrl } from "../../utils/utils";

type Props = {
  productData: ProductDataType;
  item_code: string;
  cssClass?: string;
  lastItemRef?: (node: Element | null) => void; // accept callback ref here
};

const ProductCard: FC<Props> = ({ productData, item_code, cssClass, lastItemRef }) => {
  const {
    onlineStores: { favoriteStore },
  } = useCustomContext();

  const stores = [favoriteStore];
  const [isOpen, setIsOpen] = useState(false);
  productData[favoriteStore].img_url = resolveImgUrl(favoriteStore, productData[favoriteStore].img_url);
  const { item_name, item_price, unit_qty, img_url } = productData[favoriteStore];

  return (
    <ProductCardGeneric
      lastItemRef={lastItemRef}
      cssClass={cssClass}
      productData={productData}
      ProductCardBodyVersion={
        <ProductCardBodyV2 itemName={item_name} imgUrl={img_url} onClick={() => setIsOpen(true)} />
      }
      selectedStore={favoriteStore}
      item_code={item_code}
      stores={stores}
      ProductCardFooterVersion={<ProductCardFooterV2 itemPrice={item_price} unitQty={unit_qty} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

export default ProductCard;
